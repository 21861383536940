<template>
  <v-row>
    <v-col cols="12" class="py-0 px-0">
      <SearchBar
        show-advanced-search
        info="Advanced search uses all auditors related tables where several columns from every table is included in search. NOTE: Every searched results are cached for one hour!"
        search-label="Search Auditors"
        @onSearch="onSearch"
        :is-loading="loading"
      />
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        :options.sync="options"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
      >
        <template v-slot:item.name="{ item }">
          <span class="form-link" @click="open(item)">
            {{ item.name }}
          </span>
        </template>
        <template v-slot:item.description="{ item }">
          {{ item.description }}
        </template>
        <template v-slot:item.vendorattribute_id="{ item }">
          <img
            :src="
              $vuetify.theme.dark
                ? vendorImage(item.vendor_id, true)
                : vendorImage(item.vendor_id, false)
            "
            :alt="item.vendor_id"
            class="vendor-img"
          />
        </template>
        <template v-slot:item.firewall="{ item }">
          <v-chip
            v-for="(firewall, index) in item.relatedFirewallName"
            :key="index"
            x-small
            color="neutral-4"
            class="mr-1 text-white"
          >
            {{ firewall }}
          </v-chip>
        </template>
        <template v-slot:item.src_address="{ item }">
          {{ item.srcaddr }}
        </template>
        <template v-slot:item.src_zone="{ item }">
          {{
            item.srczone === 37
              ? "any, " + item.srczonevalue
              : item.srczonevalue
          }}
        </template>
        <template v-slot:item.src_interface="{ item }">
          {{ item.src_interface }}
        </template>
        <template v-slot:item.dst_address="{ item }">
          {{ item.dstaddr }}
        </template>
        <template v-slot:item.ruleset_mode="{ item }">
          {{ item.ruleset_mode === 2 ? 'Negate' : 'Standard' }}
        </template>
        <template v-slot:item.dst_zone="{ item }">
          {{
            item.dstzone === 37
              ? "any, " + item.dstzonevalue
              : item.dstzonevalue
          }}
        </template>
        <template v-slot:item.dst_interface="{ item }">
          {{ item.dst_interface }}
        </template>
        <template v-slot:item.active="{ item }">
          <v-icon :color="activeColor(item.active)" small>
            fas fa-circle
          </v-icon>
        </template>
        <template v-slot:item.action="{ item }">
          {{ item.action }}
        </template>
        <template v-slot:item.port="{ item }">
          {{ item.port }}
        </template>
        <template v-slot:item.protocol="{ item }">
          {{ item.protocol }}
        </template>
        <template v-slot:item.severity="{ item }">
          <v-icon :class="severityClass(item.severity)"> mdi-view-grid </v-icon>
          {{ severityNames[item.severity - 1] }}
        </template>
        <template v-slot:item.groups="{ item }">
          <v-chip
            v-for="(group, index) in auditorGroups.filter((auditorGroup) =>
              item.groups.includes(auditorGroup.id)
            )"
            :key="index"
            x-small
            color="primary"
            class="elevation-2 synapsa-chip"
          >
            {{ group.name }}
          </v-chip>
        </template>
        <!-- DELETE COLUMN-->
        <template v-slot:item.delete="{ item }">
          <v-menu
            v-permission="['write auditorruleset']"
            v-model="item.deleteMenu"
            :close-on-content-click="false"
            rounded
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small icon color="bad-2" v-on="on" v-bind="attrs">
                <v-icon x-small>fas fa-trash-alt</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list width="290">
                <v-list-item>
                  <v-list-item-content class="pb-0">
                    <v-list-item-title class="font-roboto-condensed-regular"
                      >Are you sure you want to delete this Ruleset?
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-right mt-2">
                      <v-btn
                        rounded
                        text
                        color="tertiary"
                        small
                        class="text-capitalize synapsa-tab"
                        @click="cancelDelete(item)"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        text
                        color="bad-2"
                        small
                        class="text-capitalize synapsa-tab"
                        @click="deleteList(item)"
                      >
                        Delete
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
        <!-- END DELETE COLUMN-->

        <!-- TEST COLUMN-->
        <template v-slot:item.test="{ item }">
          <v-menu
            v-permission="['view auditorruleset']"
            v-model="item.test"
            :close-on-content-click="false"
            rounded
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                color="primary"
                v-on="on"
                v-bind="attrs"
                @click="test(item.id)"
              >
                <v-icon x-small>fas fa-play</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </template>
        <!-- END TEST COLUMN-->

        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <!--NAME-->
                    <td
                      v-if="expandItem.value === 'name'"
                      width="150"
                      class="pl-3"
                    >
                      Name:
                    </td>
                    <td v-if="expandItem.value === 'name'">
                      <span class="form-link" @click="open(item)">
                        {{ item.name }}
                      </span>
                    </td>
                    <!--END NAME-->

                    <!--NAME-->
                    <td
                      v-if="expandItem.value === 'ruleset_mode'"
                      width="150"
                      class="pl-3"
                    >
                      Mode:
                    </td>
                    <td v-if="expandItem.value === 'ruleset_mode'">
                      {{ item.ruleset_mode === 2 ? 'Negate' : 'Standard' }}
                    </td>
                    <!--END NAME-->

                    <!--VENDOR-->
                    <td
                      v-if="expandItem.value === 'vendorattribute_id'"
                      width="150"
                      class="pl-3"
                    >
                      Vendor:
                    </td>
                    <td v-if="expandItem.value === 'vendorattribute_id'">
                      <img
                        :src="vendorImage(item.vendor_id)"
                        :alt="item.vendor_id"
                        class="vendor-img"
                      />
                    </td>
                    <!--END VENDOR-->

                    <!--FIREWALLS-->
                    <td
                      v-if="expandItem.value === 'firewall'"
                      width="150"
                      class="pl-3"
                    >
                      Firewall / Group:
                    </td>
                    <td v-if="expandItem.value === 'firewall'">
                      <v-chip
                        v-for="(firewall, index) in item.relatedFirewallName"
                        :key="index"
                        x-small
                        color="neutral-4"
                        class="mr-1 text-white"
                      >
                        {{ firewall }}
                      </v-chip>
                    </td>
                    <!--END FIREWALLS-->

                    <!--DESCRIPTION-->
                    <td
                      v-if="expandItem.value === 'description'"
                      width="150"
                      class="pl-3"
                    >
                      Description:
                    </td>
                    <td v-if="expandItem.value === 'description'">
                      {{ item.description ? item.description : "-" }}
                    </td>
                    <!--END DESCRIPTION-->

                    <!--SRC/DST ADDR-->
                    <td
                      v-if="
                        ['src_address', 'dst_address'].includes(
                          expandItem.value
                        )
                      "
                      width="150"
                      class="pl-3"
                    >
                      {{
                        expandItem.value === "src_address"
                          ? "Source address:"
                          : "Destination address:"
                      }}
                    </td>
                    <td
                      v-if="
                        ['src_address', 'dst_address'].includes(
                          expandItem.value
                        )
                      "
                    >
                      <span v-if="expandItem.value === 'src_address'">
                        {{ item.srcaddr ? item.srcaddr : "-" }}
                      </span>
                      <span v-else>
                        {{ item.dstaddr ? item.dstaddr : "-" }}
                      </span>
                    </td>
                    <!--END SRC/DST ADDR-->

                    <!--SRC/DST ZONE-->
                    <td
                      v-if="
                        ['src_zone', 'dst_zone'].includes(expandItem.value) &&
                        item.vendor_id === 1
                      "
                      width="150"
                      class="pl-3"
                    >
                      {{
                        expandItem.value === "src_zone"
                          ? "Source zone:"
                          : "Destination zone:"
                      }}
                    </td>
                    <td
                      v-if="
                        ['src_zone', 'dst_zone'].includes(expandItem.value) &&
                        item.vendor_id === 1
                      "
                    >
                      <span v-if="expandItem.value === 'src_zone'">
                        {{
                          item.srczonevalue && item.srczone === 37
                            ? "any, " + item.srczonevalue
                            : item.srczonevalue
                            ? item.srczonevalue
                            : "-"
                        }}
                      </span>
                      <span v-else>
                        {{
                          item.dstzonevalue && item.dstzone === 37
                            ? "any, " + item.dstzonevalue
                            : item.dstzonevalue
                            ? item.dstzonevalue
                            : "-"
                        }}
                      </span>
                    </td>
                    <!--END SRC/DST ZONE-->

                    <!--SRC/DST INTERFACE-->
                    <td
                      v-if="
                        ['src_interface', 'dst_interface'].includes(
                          expandItem.value
                        ) && item.vendor_id === 2
                      "
                      width="150"
                      class="pl-3"
                    >
                      {{
                        expandItem.value === "src_interface"
                          ? "Source interface:"
                          : "Destination interface:"
                      }}
                    </td>
                    <td
                      v-if="
                        ['src_interface', 'dst_interface'].includes(
                          expandItem.value
                        ) && item.vendor_id === 2
                      "
                    >
                      <span v-if="expandItem.value === 'src_interface'">
                        {{ item.src_interface ? item.src_interface : "-" }}
                      </span>
                      <span v-else>
                        {{ item.dst_interface ? item.dst_interface : "-" }}
                      </span>
                    </td>
                    <!--END SRC/DST INTERFACE-->

                    <!--PORT-->
                    <td
                      v-if="expandItem.value === 'port'"
                      width="150"
                      class="pl-3"
                    >
                      Port:
                    </td>
                    <td v-if="expandItem.value === 'port'">
                      {{ item.port ? item.port : "-" }}
                    </td>
                    <!--END PORT-->

                    <!--PROTOCOL-->
                    <td
                      v-if="expandItem.value === 'protocol'"
                      width="150"
                      class="pl-3"
                    >
                      Protocol:
                    </td>
                    <td v-if="expandItem.value === 'protocol'">
                      {{ item.protocol ? item.protocol : "-" }}
                    </td>
                    <!--END PROTOCOL-->

                    <!--ACTION-->
                    <td
                      v-if="expandItem.value === 'action'"
                      width="150"
                      class="pl-3"
                    >
                      Action:
                    </td>
                    <td v-if="expandItem.value === 'action'">
                      {{ item.action ? item.action : "-" }}
                    </td>
                    <!--END ACTION-->

                    <!--Log Start-->
                    <td
                      v-if="expandItem.value === 'log_start'"
                      width="150"
                      class="pl-3"
                    >
                      Log Start:
                    </td>
                    <td v-if="expandItem.value === 'log_start'">
                      {{ item.log_start ? item.log_start : "-" }}
                    </td>
                    <!--END Log Start--->
                    <!--Log End-->
                    <td
                      v-if="expandItem.value === 'log_end'"
                      width="150"
                      class="pl-3"
                    >
                      Log End:
                    </td>
                    <td v-if="expandItem.value === 'log_end'">
                      {{ item.log_end ? item.log_end : "-" }}
                    </td>
                    <!--END Log End--->
                    <!--Log Setting-->
                    <td
                      v-if="expandItem.value === 'log_setting'"
                      width="150"
                      class="pl-3"
                    >
                      Log Setting:
                    </td>
                    <td v-if="expandItem.value === 'log_setting'">
                      {{ item.log_setting ? item.log_setting : "-" }}
                    </td>
                    <!--END Log Setting--->

                    <!--TAG-->
                    <td
                      v-if="expandItem.value === 'tag'"
                      width="150"
                      class="pl-3"
                    >
                      Tag:
                    </td>
                    <td v-if="expandItem.value === 'tag'">
                      {{ item.tag ? item.tag : "-" }}
                    </td>
                    <!--END TAG-->

                    <!--Profile Setting-->
                    <td
                      v-if="expandItem.value === 'profile_setting'"
                      width="150"
                      class="pl-3"
                    >
                      Profile Setting:
                    </td>
                    <td v-if="expandItem.value === 'profile_setting'">
                      {{ item.profile_setting ? item.profile_setting : "-" }}
                    </td>
                    <!--END Profile Setting-->

                    <!--Virus-->
                    <td
                      v-if="expandItem.value === 'profile_setting_virus'"
                      width="150"
                      class="pl-3"
                    >
                      Virus:
                    </td>
                    <td v-if="expandItem.value === 'profile_setting_virus'">
                      {{
                        item.profile_setting_virus
                          ? item.profile_setting_virus
                          : "-"
                      }}
                    </td>
                    <!--END Virus-->

                    <!--Spyware-->
                    <td
                      v-if="expandItem.value === 'profile_setting_spyware'"
                      width="150"
                      class="pl-3"
                    >
                      Spyware:
                    </td>
                    <td v-if="expandItem.value === 'profile_setting_spyware'">
                      {{
                        item.profile_setting_spyware
                          ? item.profile_setting_spyware
                          : "-"
                      }}
                    </td>
                    <!--END Spyware-->

                    <!--Vulnerability-->
                    <td
                      v-if="
                        expandItem.value === 'profile_setting_vulnerability'
                      "
                      width="150"
                      class="pl-3"
                    >
                      Vulnerability:
                    </td>
                    <td
                      v-if="
                        expandItem.value === 'profile_setting_vulnerability'
                      "
                    >
                      {{
                        item.profile_setting_vulnerability
                          ? item.profile_setting_vulnerability
                          : "-"
                      }}
                    </td>
                    <!--END Vulnerability-->

                    <!--Wildfire-->
                    <td
                      v-if="expandItem.value === 'profile_setting_wildfire'"
                      width="150"
                      class="pl-3"
                    >
                      Wildfire:
                    </td>
                    <td v-if="expandItem.value === 'profile_setting_wildfire'">
                      {{
                        item.profile_setting_wildfire
                          ? item.profile_setting_wildfire
                          : "-"
                      }}
                    </td>
                    <!--END Wildfire-->

                    <!--Url Filtering-->
                    <td
                      v-if="
                        expandItem.value === 'profile_setting_url_filtering'
                      "
                      width="150"
                      class="pl-3"
                    >
                      Url Filtering:
                    </td>
                    <td
                      v-if="
                        expandItem.value === 'profile_setting_url_filtering'
                      "
                    >
                      {{
                        item.profile_setting_url_filtering
                          ? item.profile_setting_url_filtering
                          : "-"
                      }}
                    </td>
                    <!--END Url Filtering-->

                    <!--File Blocking-->
                    <td
                      v-if="
                        expandItem.value === 'profile_setting_file_blocking'
                      "
                      width="150"
                      class="pl-3"
                    >
                      File Blocking:
                    </td>
                    <td
                      v-if="
                        expandItem.value === 'profile_setting_file_blocking'
                      "
                    >
                      {{
                        item.profile_setting_file_blocking
                          ? item.profile_setting_file_blocking
                          : "-"
                      }}
                    </td>
                    <!--END File Blocking-->

                    <!--Data Filtering-->
                    <td
                      v-if="
                        expandItem.value === 'profile_setting_data_filtering'
                      "
                      width="150"
                      class="pl-3"
                    >
                      Data Filtering:
                    </td>
                    <td
                      v-if="
                        expandItem.value === 'profile_setting_data_filtering'
                      "
                    >
                      {{
                        item.profile_setting_data_filtering
                          ? item.profile_setting_data_filtering
                          : "-"
                      }}
                    </td>
                    <!--END Data Filtering-->

                    <!--Application-->
                    <td
                      v-if="expandItem.value === 'application'"
                      width="150"
                      class="pl-3"
                    >
                      Application:
                    </td>
                    <td v-if="expandItem.value === 'application'">
                      {{ item.application ? item.application : "-" }}
                    </td>
                    <!--END Application-->

                    <!--ACTIVE-->
                    <td
                      v-if="expandItem.value === 'active'"
                      width="150"
                      class="pl-3"
                    >
                      Active:
                    </td>
                    <td v-if="expandItem.value === 'active'">
                      <v-icon :color="activeColor(item.active)" small>
                        fas fa-circle
                      </v-icon>
                    </td>
                    <!--END ACTIVE-->

                    <!--GROUPS-->
                    <td
                      v-if="expandItem.value === 'groups'"
                      width="150"
                      class="pl-3"
                    >
                      Groups:
                    </td>
                    <td v-if="expandItem.value === 'groups'">
                      <v-chip
                        v-for="(
                          group, index
                        ) in auditorGroups.filter((auditorGroup) =>
                          item.groups.includes(auditorGroup.id)
                        )"
                        :key="index"
                        x-small
                        color="primary"
                        class="elevation-2 synapsa-chip"
                      >
                        {{ group.name }}
                      </v-chip>
                    </td>
                    <!--END GROUPS-->

                    <td v-if="expandItem.value === 'severity'" class="pl-3">
                      {{ expandItem.text }}
                    </td>
                    <td v-if="expandItem.value === 'severity'">
                      <v-icon :class="severityClass(item.severity)">
                        mdi-view-grid
                      </v-icon>
                      {{ severityNames[item.severity - 1] }}
                    </td>

                    <!--DELETE-->
                    <td
                      v-if="expandItem.value === 'delete'"
                      width="150"
                      class="pl-3"
                    >
                      Delete
                    </td>
                    <td v-if="expandItem.value === 'delete'">
                      <v-menu
                        v-permission="['write auditorruleset']"
                        v-model="item.deleteMenu"
                        :close-on-content-click="false"
                        rounded
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            icon
                            color="bad-2"
                            v-on="on"
                            v-bind="attrs"
                          >
                            <v-icon x-small>fas fa-trash-alt</v-icon>
                          </v-btn>
                        </template>

                        <v-card>
                          <v-list width="350">
                            <v-list-item>
                              <v-list-item-content class="pb-0">
                                <v-list-item-title
                                  >Are you sure you want to delete this Ruleset?
                                </v-list-item-title>
                                <v-list-item-subtitle class="text-right mt-2">
                                  <v-btn
                                    rounded
                                    text
                                    color="tertiary"
                                    small
                                    class="text-capitalize"
                                    @click="cancelDelete(item)"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    rounded
                                    text
                                    color="bad-2"
                                    small
                                    class="text-capitalize"
                                    @click="deleteList(item)"
                                  >
                                    Delete
                                  </v-btn>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </td>
                    <!--END DELETE-->

                    <!--TEST-->
                    <td
                      v-if="expandItem.value === 'test'"
                      width="150"
                      class="pl-3"
                    >
                      Test integrity
                    </td>
                    <td v-if="expandItem.value === 'test'">
                      <v-menu
                        v-permission="['view auditorruleset']"
                        v-model="item.test"
                        :close-on-content-click="false"
                        rounded
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            icon
                            color="primary"
                            v-on="on"
                            v-bind="attrs"
                            @click="test(item.id)"
                          >
                            <v-icon x-small>fas fa-play</v-icon>
                          </v-btn>
                        </template>
                      </v-menu>
                    </td>
                    <!--END TEST-->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
    <test
      v-if="!loading"
      @onClose="showTestModal = false"
      :id="testId"
      :show="showTestModal"
    />
  </v-row>
</template>

<script>
import permission from "@/directive/permission/permission.js";
import SearchBar from "@/components/SearchBar";
import Test from "@/layout/FormSidebar/auditor/ruleset/test";

export default {
  directives: { permission },
  components: {
    SearchBar,
    Test,
  },
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    auditorGroups: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],
      options: {},
      location: "",

      page: 1,
      pageTotalCount: 1,
      size: 1,
      order: null,
      by: null,

      sizes: [5, 10, 25, 50, 100, 200],
      severityColors: ["neutral-3", "good-3", "medium-3", "bad-3"],
      severityNames: ["Low", "Medium", "High", "Critical"],
      deleteMenu: false,

      showTestModal: false,
      testId: undefined,
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.options.page = newValue;
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue !== this.sizes.indexOf(this.tableSize)) {
          this.options.itemsPerPage = this.sizes[newValue];
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
    expandedHeaders: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.length === 0) {
          this.expanded = [];
        }
      },
    },
    options: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue.sortBy &&
          newValue.sortDesc &&
          newValue.sortBy.length > 0 &&
          newValue.sortDesc.length > 0
        ) {
          const order = newValue.sortDesc[0] ? "desc" : "asc";
          const by = newValue.sortBy[0];
          this.$emit("change", {
            page: this.page,
            size: this.sizes[this.size],
            order: order,
            orderBy: by,
          });
        }
      },
    },
  },
  created() {
    this.location = window.location.origin;
  },
  methods: {
    test(id) {
      this.showTestModal = true;
      this.testId = id;
    },
    vendorImage(vendor, dark) {
      if (vendor === 1) {
        return dark
          ? this.location + "/images/vendors/paloalto_white.png"
          : this.location + "/images/vendors/paloalto.png";
      } else if (vendor === 2) {
        return dark
          ? this.location + "/images/vendors/fortinet_white.png"
          : this.location + "/images/vendors/fortinet.png";
      } else if (vendor === 3) {
        return dark
          ? this.location + "/images/vendors/checkpoint_white.png"
          : this.location + "/images/vendors/checkpoint.png";
      } else if (vendor === 4) {
        return this.location + "/images/vendors/sophos.png";
      } else {
        return null;
      }
    },
    activeColor(status) {
      if (status === 1) {
        return "#87d05a";
      } else if (status === 2) {
        return "#e53355";
      } else {
        return "#c5c5c5";
      }
    },
    open(item) {
      this.idAddForm = item.id;
      this.keywordAddForm = "auditor";
    },
    cancelDelete(item) {
      this.$emit("cancelDelete", item);
    },
    deleteList(item) {
      this.$emit("deleteList", item);
    },
    onSearch(event) {
      this.$emit("onSearch", event);
    },
    severityClass(status) {
      if (status === 1 || status === "1") {
        return "signal-icon--low";
      } else if (status === 2 || status === "2") {
        return "signal-icon--medium";
      } else if (status === 3 || status === "3") {
        return "signal-icon--high";
      } else {
        return "signal-icon--critical";
      }
    },
  },
};
</script>
